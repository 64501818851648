<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card>
                    <v-card-title>
                        {{ $t('users') }}

                        <v-spacer></v-spacer>

                        <v-btn color="info" @click="onAdd">
                            <v-icon dark left>mdi-plus</v-icon>
                            {{ $t('add_user') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field
                                id="searchField"
                                v-model="search"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                            id="dataTable"
                            :headers="headers"
                            :items="users"
                            :search="search"
                            :items-per-page="15"
                            @click:row="onClickRow"
                    ></v-data-table>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog id="dialog" v-model="dialog" persistent width="75%">
            <v-card>

                <v-form @submit.prevent="save">
                    <v-card-title>{{ $t('user_details') }}</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                        id="emailField"
                                        :label="$t('email')"
                                        type="email"
                                        v-model="userDetails.email"
                                />

                                <v-text-field
                                        id="nameField"
                                        :label="$t('name')"
                                        type="text"
                                        v-model="userDetails.name"
                                />

                                <v-autocomplete
                                        id="genderField"
                                        :label="$t('gender')"
                                        :items="getGender"
                                        :value="userDetails.gender"
                                        v-model="userDetails.gender"
                                />

                                <v-text-field v-if="newAccount"
                                              id="passwordField"
                                              :label="$t('password')"
                                              type="password"
                                              v-model="userDetails.password"
                                />

                                <v-text-field
                                        id="companyField"
                                        :label="$t('company')"
                                        type="text"
                                        v-model="userDetails.company"
                                />

                                <v-autocomplete
                                        id="companyTypeField"
                                        @change="onCompanyTypeChange"
                                        :label="$t('company_type')"
                                        :items="getCompanyTypes"
                                        :value="userDetails.company_type"
                                        v-model="userDetails.company_type"
                                >
                                </v-autocomplete>

                                <v-text-field
                                        id="jobTitleField"
                                        :label="$t('job_title')"
                                        type="text"
                                        v-model="userDetails.job_title"
                                />
                            </v-col>

                            <v-col cols="4">
                                <v-text-field
                                        id="addressField"
                                        :label="$t('address')"
                                        type="text"
                                        v-model="userDetails.address"
                                />

                                <v-text-field
                                        id="postcodeField"
                                        :label="$t('postcode')"
                                        type="text"
                                        v-model="userDetails.postcode"
                                />

                                <v-text-field
                                        id="cityField"
                                        :label="$t('city')"
                                        type="text"
                                        v-model="userDetails.city"
                                />

                                <v-text-field
                                        id="countryField"
                                        :label="$t('country')"
                                        type="text"
                                        v-model="userDetails.country"
                                />
                            </v-col>

                            <v-col cols="4">
                                <v-menu v-model="showDatePicker"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="userDetails.valid_until"
                                                :label="$t('valid_until')"
                                                v-bind="attrs"
                                                readonly
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="userDetails.valid_until"
                                                   @input="showDatePicker = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-text-field
                                        id="latestField"
                                        :label="$t('latest_login')"
                                        type="text"
                                        readonly
                                        v-model="userDetails.latest_login"
                                />

                                <v-autocomplete
                                        id="roleField"
                                        :disabled="setRoleDisabled"
                                        :label="$t('role')"
                                        :items="getRoles"
                                        :value="userDetails.role"
                                        v-model="userDetails.role"
                                >
                                </v-autocomplete>

                                <v-autocomplete
                                        id="statusField"
                                        :label="$t('status')"
                                        :items="getUserStatus"
                                        :value="userDetails.status"
                                        v-model="userDetails.status"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn id="cancelButton" color="error"
                               @click="dialog = false"
                               v-shortkey="['esc']" @shortkey="dialog = false"
                        >
                            <v-icon dark left>mdi-cancel</v-icon>
                            {{ $t('cancel') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn id="submitButton" type="submit" color="info">
                            <v-icon dark left>mdi-send</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import userService from '@/services/UserService.js'

    export default {
        name: "Users",

        computed: {
            ...mapState(['isAdmin']),
            ...mapGetters(['getGender', 'getUserStatus', 'getCompanyTypes', 'getRoles']),
        },

        created() {
            // Route guard
            if (!this.isAdmin) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                headers: [
                    {text: this.$t('id'), value: 'id'},
                    {text: this.$t('name'), value: 'name'},
                    {text: this.$t('company'), value: 'company'},
                    {text: this.$t('company_type'), value: 'company_type'},
                    {text: this.$t('role'), value: 'role'},
                    {text: this.$t('status'), value: 'status'},
                    {text: this.$t('latest_login'), value: 'latest_login'},
                ],
                users: [],
                search: '',
                dialog: false,
                showDatePicker: false,
                newAccount: false,

                userDetails: {
                    id: '',
                    name: '',
                    gender: '',
                    password: '',
                    email: '',
                    company: '',
                    company_type: '',
                    job_title: '',
                    address: '',
                    postcode: '',
                    city: '',
                    country: '',
                    role: '',
                    status: '',
                    latest_login: '',
                    valid_until: '',
                },
                setRoleDisabled: false,

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                userService.all()
                    .then(response => {
                        this.users = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            onClickRow(rowData) {
                this.dialog = true

                this.userDetails = rowData
                if (this.userDetails.company_type == "Breeder") {
                    this.setRoleDisabled = false
                }
                else {
                    this.setRoleDisabled = true
                }
            },

            onAdd() {
                this.dialog = true
                this.newAccount = true

                this.userDetails.id = ''
                this.userDetails.name = ''
                this.userDetails.email = ''
                this.userDetails.gender = ''
                this.userDetails.password = ''
                this.userDetails.company = ''
                this.userDetails.company_type = ''
                this.userDetails.job_title = ''
                this.userDetails.address = ''
                this.userDetails.postcode = ''
                this.userDetails.city = ''
                this.userDetails.country = ''
                this.userDetails.role = ''
                this.userDetails.status = ''
                this.userDetails.valid_until = ''
            },

            onCompanyTypeChange() {
                if (this.userDetails.company_type == "Breeder") {
                    this.setRoleDisabled = false
                }
                else {
                    this.setRoleDisabled = true
                }
            },

            save() {
                this.dialog = false
                this.newAccount = false

                if (!this.userDetails.id) {
                    // Create new user
                    userService.create(this.userDetails)
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('data_saved')

                            this.init()
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
                else {
                    // Update user
                    userService.update(this.userDetails)
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('data_saved')

                            this.init()
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },
        },
    }
</script>

